@import url("https://fonts.googleapis.com/css2?family=Viga&display=swap");

* {
  font-family: "Viga", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
}

.layout-home .site-layout-content,
.verify-container {
  background: url(../src/assets/img/hero.jpg);
  background-size: cover;
  background-position: center;
}
.verify-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background-color: rgba(255, 255, 255, 0.85);
  width: 70%;
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.form.formal-education,
.form.informal-education,
.form.activity {
  width: 100%;
  padding: 10px 0;
  box-shadow: none;
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 5px;
  box-sizing: border-box;
}

.form-group label {
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea,
.form-group input[type="number"] {
  width: 100% !important;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
}

.form-group select:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.form-group select:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  border-right-width: 1px;
  outline: 0;
}

.button {
  width: 100%;
  margin: auto;
  cursor: pointer;
  background-color: #1890ff;
  color: #fff;
  padding: 5px 20px;
  border: 1px solid #999;
  border-radius: 5px;
  transition: 0.3s;
}

.button:hover {
  background-color: #0371d8;
}

.button:disabled,
.button:disabled:hover {
  cursor: not-allowed;
  background-color: #ccc;
}

.error-text {
  color: red;
  text-align: center;
}

.card {
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 30px;
}

/* profile page */

section.education,
section.activity,
section.alumni-info {
  width: 100%;
  max-width: 1000px;
  border: 1px solid #ccc;
  padding: 50px 20px;
  margin: 40px auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

section.alumni-info {
  padding-top: 20px;
}

.content-modal {
  text-align: center;
  text-transform: capitalize;
  font-size: 20px;
}

.ant-table {
  border-radius: 5px !important;
  overflow: auto;
  border-top: none;
  border-bottom: none;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}
th.ant-table-cell {
  background-color: #256ad1 !important;
  color: #fff !important;
  padding: 10px !important;
}

td.ant-table-cell {
  border: none !important;
  border-bottom: 1px solid #eee !important;
  transition: 0.3s;
}
.ant-table tr:nth-child(even) {
  background-color: #eee;
}
.ant-table tr:nth-child(odd) {
  background-color: #fff;
}
.ant-table-row:hover td {
  background: #ddd !important;
  transition: 0.3s;
}
th.ant-table-cell,
td.ant-table-cell {
  max-width: 170px;
}

.ant-btn.ant-btn-round.ant-btn-primary.ant-btn-sm {
  margin-right: 5px;
  background-color: #18978f;
  border: none;
}

th.ant-descriptions-item-label {
  background-color: #fff !important;
}
td.ant-descriptions-item-content {
  background-color: #efefef;
}

.ant-descriptions-row,
.ant-descriptions-view {
  border-color: #ddd !important;
}
.ant-descriptions-view {
  border-radius: 3px !important;
  overflow: hidden;
}

.ant-divider-inner-text {
  font-size: 19px;
}
.ant-divider-horizontal.ant-divider-with-text {
  margin-top: 0px !important;
}

.input-search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.input-search-container span.ant-input-affix-wrapper {
  width: 80%;
}

.excel-btn {
  width: 32px;
  cursor: pointer;
}

/* charts */

.chart-wrapper {
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  box-sizing: border-box;
}
.chart-wrapper:hover {
  background-color: #001529;
}
.chart-wrapper h2 {
  font-size: 16px;
}
.chart-wrapper:hover h2 {
  color: #eaeaea;
}

/* media queries */
@media screen and (max-width: 678px) {
  * {
    font-size: 13px;
  }
  .form {
    width: 100%;
    margin: 20px auto;
    padding: 13px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  }
  .form.verify {
    width: 90%;
  }

  .form-group {
    flex-direction: column;
  }

  .form-group label {
    width: 100%;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100% !important;
    padding: 5px;
    border-radius: 3px;
  }

  section.alumni-info {
    padding: 10px 0 0 0;
  }

  header.ant-layout-header {
    padding: 0 !important;
  }

  div.site-layout-content {
    padding: 15px;
  }

  main.ant-layout-content {
    padding: 0 !important;
  }

  .ant-table-wrapper {
    overflow: auto;
  }

  .ant-divider-inner-text {
    font-size: 16px;
  }
}
