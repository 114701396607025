.first-chart, .second-chart{
  width: 100%;
  margin: 30px 0;
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 20px;
  box-sizing: border-box;
}
.third-chart {
  width: 90%;
  max-width: 100%;
  margin: 30px 0;
}
.fourth-chart {
  width: 50%;
}

@media screen and (min-width: 1600px) {
  .first-chart, .second-chart{
    grid-template-columns: 25% 55%;
  }
  .fourth-chart {
    width: 40%;
  }
}