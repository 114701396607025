@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.container {
  width: 100%;
  min-height: 100vh;
  background: url(../assets/img/hero.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 1200px;
  max-width: 90%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 60px;
  border-radius: 10px;
  position: relative;
  z-index: 999;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.auth {
  width: 50%;
  position: absolute;
  bottom: -30px;
  left: 40px;
}
.auth button,
.profile-btn {
  width: 40%;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.3);
}
.auth button:last-child,
.profile-btn {
  background-color: orange;
  border: none;
  color: white;
}
.profile-btn {
  width: 30%;
  height: 50px;
}

.title {
  font-size: 2.5em;
  font-weight: 500;
  color: #fff;
}

.salam {
  text-align: right;
  font-size: 1.2em;
}

.text {
  font-size: 1.1em;
  text-align: justify;
  margin-bottom: 10px;
}

.akun {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
  .content {
    margin: 30px auto;
  }
  .auth {
    width: 100%;
    position: static;
  }
  .auth button,
  .profile-btn {
    width: 40%;
  }
}

@media screen and (max-width: 576px) {
  .container {
    position: relative;
    height: 100%;
  }
  .content {
    padding: 20px;
    width: 100%;
  }
  .auth button,
  .profile-btn {
    width: 100%;
    height: 40px;
    margin-bottom: 5px;
  }

  .title {
    font-size: 1.8em;
    margin-bottom: 20px;
  }
  .salam,
  .text {
    font-size: 1em;
  }
}
