.button {
  width: 100%;
  margin: auto;
  cursor: pointer;
  background-color: #1890FF;
  color: #fff;
  padding: 5px 20px;
  border: 1px solid #999;
  border-radius: 5px;
  transition: .3s;
}

.button:hover {
  background-color: #0371d8;
}